<template>
    <v-expansion-panel>
        <v-expansion-panel-header>
            Buscar vendedor
            <template v-slot:actions>
                <v-btn class="mr-5" @click.native.stop="reloadView++" icon small>
                    <v-icon color="primary">mdi-reload</v-icon>
                </v-btn>
                <v-btn @click="$store.dispatch('deleteWindowByUuid', { uuid: win.uuid })" icon small>
                    <v-icon color="error">mdi-close</v-icon>
                </v-btn>
            </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content :key="reloadView">
            <v-card>
                <v-tabs v-model="tab" dark background-color="primary" icons-and-text>
                    <v-tabs-slider color="red"></v-tabs-slider>
                    <v-tab href="#tab-1">
                        Buscar vendedor
                        <v-icon>mdi-account-search</v-icon>
                    </v-tab>
                </v-tabs>

                <v-tabs-items v-model="tab">
                    <v-tab-item value="tab-1">
                        <v-card flat>
                            <v-card-text class="pa-1">
                                <v-card>
                                    <GeneralFilter class="background border-box"
                                        :filterEndpoint="$API.sellers.getAllSellersFiltered" :search="search"
                                        @emptyFilter="getAllSellers" @filtered="handleFilter">
                                        <DxDataGrid class="isScrolledByGeneralFilter" :data-source="sellers"
                                            key-expr="ID_Seller" :show-row-lines="showRowLines" :show-borders="showBorders"
                                            :row-alternation-enabled="rowAlternationEnabled">
                                            <DxScrolling row-rendering-mode="virtual" />
                                            <DxPaging :page-size="10" />
                                            <DxColumn :width="80" cell-template="show-template" caption=""></DxColumn>
                                            <DxColumn data-field="ID_Seller" caption="ID"></DxColumn>
                                            <DxColumn data-field="FirstName" caption="Nombres"></DxColumn>
                                            <DxColumn data-field="LastName" caption="Apellidos"></DxColumn>
                                            <DxColumn data-field="Email" caption="Correo"></DxColumn>
                                            <template #show-template="{ data }">
                                                <div>
                                                    <a href="javascript:void(0)" @click="sellerSelected(data)">
                                                        <v-icon color="primary" small class="mr-2">mdi-eye</v-icon>
                                                    </a>
                                                    <a href="javascript:void(0)" v-if="data.data.ID_Seller != myseller"
                                                        @click.prevent="sellerToDelete = data.data;
                                                        showAlert('question', 'Eliminar',
                                                            '¿Está seguro que desea eliminar este vendedor?', 'deletecustomer'
                                                        )">
                                                        <v-icon small color="error">mdi-delete</v-icon>
                                                    </a>
                                                </div>
                                            </template>
                                        </DxDataGrid>
                                    </GeneralFilter>
                                </v-card>

                            </v-card-text>
                        </v-card>
                    </v-tab-item>
                </v-tabs-items>
            </v-card>
        </v-expansion-panel-content>

        <!-- <v-dialog v-model="deleteDialog" max-width="290">
            <delete-seller-dialog :data="sellerToDelete"
                                @close="closeDialog"
            ></delete-seller-dialog>
        </v-dialog> -->
        <alerts v-if="alert.show" v-on:close_alert="closeAlert" v-on:accept_alert="acceptAlert" :properties="alert">
        </alerts>
    </v-expansion-panel>

    <!--    <manage-seller @reloadData="reloadSellers"-->
    <!--                 v-if="showSeller" :key="showSeller.data.ID_Seller"-->
    <!--                 :seller="showSeller.data"></manage-seller>-->
</template>

<script>
import { DxDataGrid, DxColumn, DxScrolling, DxPaging } from 'devextreme-vue/data-grid';
import { mapGetters } from 'vuex';
import Alerts from "@/components/Alerts";
import GeneralFilter from '../../../../components/GeneralFilter.vue';

export default {
    components: { GeneralFilter, DxDataGrid, DxColumn, DxScrolling, DxPaging, Alerts },
    name: 'SellerList',
    props: ['win'],
    data() {
        return {
            alert: {
                type: "success",
                show: false,
                header: "",
                body: "",
            },
            search: {
                filter: null
            },
            tab: null,
            defaultItem: {
                sellername: '',
                firstname: '',
                lastname: '',
                email: '',
            },
            sellers: null,
            showSeller: false,
            deleteDialog: false,
            sellerToDelete: null,
            showRowLines: true,
            showBorders: true,
            rowAlternationEnabled: true,
            ID_User: null,
            reloadView: 0
        }
    },

    mounted() {
        this.ID_User = JSON.parse(localStorage.getItem("user")).id;
        this.getAllSellers();
    },
    computed: {
        ...mapGetters(['reloadData'])
    },
    watch: {
        reloadData: {
            immediate: true,
            handler: 'reloadSellers'
        }
    },
    methods: {
        handleFilter(response) {
            this.sellers = response;
        },
        closeAlert() {
            this.alert.show = false;
        },
        deleteSeller() {
            this.$API.sellers.deleteSeller(this.sellerToDelete.ID_Seller)
                .then(() => {
                    this.showAlert("success", "Vendedor eliminado", "El vendedor ha sido eliminado correctamente");
                    this.getAllSellers()
                })
        },
        acceptAlert() {
            this.alert.show = false;

            if (this.alert.options == "deletecustomer") {
                this.deleteSeller(this.sellerToDelete);
            }
        },
        showAlert(type, header, body, options = null) {
            type = type == null ? "danger" : type;
            this.alert.type = type;
            this.alert.header = header;
            this.alert.body = body;
            this.alert.show = true;
            this.alert.options = options != null ? options : null;
        },
        getAllSellers() {
            this.$API.sellers.getAllSellers()
                .then(response => {
                    this.sellers = response;
                })
        },
        async reloadSellers() {
            if (this.reloadData == 'sellers') {
                await this.getAllSellers();
                this.$store.dispatch('reloadData', null)
            }
        },
        closeDialog(payload) {
            this.sellerToDelete = null;
            this.deleteDialog = false;
            if (payload.isDeleted) {
                this.getAllSellers()
            }
        },
        sellerSelected(data) {
            this.$store.dispatch('addWindow', {
                'name': 'SellerData',
                component: 'SellerData',
                unique: false,
                meta: { seller: data.data }
            })
        },
        reload() {
            this.getAllSellers()
            setTimeout(() => {
                window.scrollTo(0, this.$store.getters.getScrollTop)
            }, 500);
        },
    }
}
</script>

<style scoped>
.border-box {
    border-radius: 15px !important;
}

.background {
    background: white !important;
    color: white;
}
</style>
